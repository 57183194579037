// NEW CI&T
export default {
  apiKey: "AIzaSyCRztlVVFeXNCegc1YK7EEhpjqnlB_zcl0",
  authDomain: "emc-kr-dev-d.firebaseapp.com",
  databaseURL: "https://emc-kr-dev-d.firebaseio.com",
  projectId: "emc-kr-dev-d",
  storageBucket: "emc-kr-dev-d.appspot.com",
  messagingSenderId: "359668273288",
  appId: "1:359668273288:web:1adaefc2d8b64c8ad68f3d",
  measurementId: "G-YYKCJJRF05"
}

// export default{
//   apiKey: "AIzaSyDpjmpw0L8aWOaSsavrI01Bt5rcnL3d8Us",
//   authDomain: "emc-zdnacloud-lgeintegration-t.firebaseapp.com",
//   projectId: "emc-zdnacloud-lgeintegration-t",
//   storageBucket: "emc-zdnacloud-lgeintegration-t.appspot.com",
//   messagingSenderId: "170844542752",
//   appId: "1:170844542752:web:dc208162e1db061cefe945",
//   measurementId: "G-YYKCJJRF05"
// }

// export default {
//   apiKey: "AIzaSyBYo70RoVftf0RV5hbB5qLSCQazldZCSpM",
//   authDomain: "emc-zdnacloud-scalability-t.firebaseapp.com",
//   projectId: "emc-zdnacloud-scalability-t",
//   storageBucket: "emc-zdnacloud-scalability-t.appspot.com",
//   messagingSenderId: "507285808513",
//   appId: "1:507285808513:web:f0f442f451fd411ee77480",
//   measurementId: "G-YYKCJJRF05"
// }