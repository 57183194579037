export const TABLES_LIST = [
  'myDevices',
  'newDeviceSetup',
  'appSetup',
  'deviceView',
  'myCollection',
  'deviceSettings',
  'androidUpdatesDeployment',
  'deviceUsers',
  'wirelessInsight',
  'wifiInsight',
  'wifiDeviceAllAnalysis',
  'wifiDeviceRoamAnalysis',
  'cellularInsights',
  'cellularDeviceAllAnalysis',
  'cellularDeviceVoiceAnalysis',
  'cellularDeviceIPAnalysis',
  'cellularDeviceThroughputAnalysis',
  'logger',
  'loggerDeviceSelection',
  'loggerDetailDevices',
]
export const MANDATORY_COLUMNS = {
  myDevices: [],
  newDeviceSetup: [],
  appSetup: [],
  deviceView: [],
  myCollection: [],
  deviceSettings: [],
  androidUpdatesDeployment: [],
  deviceUsers: [],
  wirelessInsight: [
    { label: 'Group Name', value: 'groupName' },
    { label: 'Actions', value: 'actions' },
  ],
  wifiInsight: [
    { label: 'DEVICE NAME', value: 'deviceName' },
    { label: 'VIEW DEVICE', value: 'viewDevice' },
  ],
  wifiDeviceAllAnalysis: [
    { label: 'TIMESTAMP', value: 'utc_timestamp' },
    { label: 'EVENT TYPE (WLAN CODE)', value: 'wlan_code' },
  ],
  wifiDeviceRoamAnalysis: [
    { label: 'TIMESTAMP', value: 'utc_timestamp' },
    { label: 'EVENT TYPE (WLAN CODE)', value: 'wlan_code' },
  ],
  cellularInsights: [
    { label: 'DEVICE NAME', value: 'deviceName' },
    { label: 'VIEW DEVICE', value: 'viewDevice' },
  ],
  cellularDeviceAllAnalysis: [
    { label: 'TIMESTAMP', value: 'utc_timestamp' },
    { label: 'EVENT TYPE', value: 'eventType' },
    { label: 'VIEW EVENT', value: 'viewEvent' },
  ],
  cellularDeviceVoiceAnalysis: [
    { label: 'TIMESTAMP', value: 'utc_timestamp' },
    { label: 'CALL TYPE', value: 'call_Type' },
    { label: 'VIEW EVENT', value: 'viewEvent' },
  ],
  cellularDeviceIPAnalysis: [
    { label: 'TIMESTAMP', value: 'utc_timestamp' },
    { label: 'EVENT TYPE', value: 'eventType' },
    { label: 'VIEW EVENT', value: 'viewEvent' },
  ],
  cellularDeviceThroughputAnalysis: [
    { label: 'TIMESTAMP', value: 'utc_timestamp' },
    { label: 'EVENT TYPE', value: 'eventType' },
    { label: 'VIEW EVENT', value: 'viewEvent' },
  ],
  logger: [],
  loggerDeviceSelection: [],
  loggerDetailDevices: [],
}
export const OPT_COLUMNS = {
  myDevices: [
    { label: 'Presence Status', value: 'presenceStatus' },
    { label: 'Model', value: 'model' },
    { label: 'Device Name', value: 'deviceName' },
    { label: 'Device Group', value: 'groups' },
    { label: 'OS Version', value: 'formattedOsVersion' },
    { label: 'Security Patch Date', value: 'securityPatch' },
    { label: 'Serial Number', value: 'serialNumber' },
    { label: 'IMEI', value: 'imei' },
    { label: 'Battery Health', value: 'batteryType' },
    { label: 'Last Connected', value: 'lastConnected' },
    { label: 'IP Address', value: 'ipAddress' },
    { label: 'Actions', value: 'actions' },
  ],
  newDeviceSetup: [
    { label: 'Setup Name', value: 'name' },
    { label: 'Setup Category', value: 'category' },
    { label: 'Android Updates', value: 'fotaEnabled' },
    { label: 'Device Group Assignment', value: 'groups' },
    { label: 'Description', value: 'description' },
    { label: 'Date Modified', value: 'updatedDateTime' },
    { label: 'Actions', value: 'actions' },
  ],
  appSetup: [
    { label: 'Setup Name', value: 'name' },
    { label: 'Setup Status', value: 'deploymentStatus' },
    { label: 'App Name', value: 'appName' },
    { label: 'App Version', value: 'appVersion' },
    { label: 'Hosting Location', value: 'hostingLocation' },
    { label: 'Description', value: 'description' },
    { label: 'Created', value: 'createdDateTime' },
    { label: 'Created By', value: 'createdBy' },
    { label: 'Last Applied', value: 'appliedAt' },
    { label: 'Last Applied By', value: 'appliedBy' },
    { label: 'Last Modified', value: 'updatedDateTime' },
    { label: 'Last Modified By', value: 'modifiedBy' },
    { label: 'Actions', value: 'actions' },
  ],
  deviceView: [
    { label: 'Presence Status', value: 'presenceStatus' },
    { label: 'Model', value: 'model' },
    { label: 'Update Status', value: 'updateStatus' },
    { label: 'Device Name', value: 'deviceName' },
    { label: 'Device Group', value: 'groups' },
    { label: 'OS Version', value: 'formattedOsVersion' },
    { label: 'Security Patch Date', value: 'securityPatch' },
    { label: 'OS Build Id', value: 'buildId' },
    { label: 'MX Version', value: 'mxVersion' },
    { label: 'Serial Number', value: 'serialNumber' },
    { label: 'Last Connected', value: 'lastConnected' },
    { label: 'Actions', value: 'actions' },
  ],
  myCollection: [
    { label: 'App Name', value: 'appName' },
    { label: 'Hosting Location', value: 'hostingLocation' },
    { label: 'App Version', value: 'appVersion' },
    { label: 'Developer', value: 'developer' },
    { label: 'Package Name', value: 'appPackage' },
    { label: 'App Description', value: 'appDescription' },
    { label: 'Actions', value: 'actions' },
  ],
  deviceSettings: [
    { label: 'Setting Name', value: 'name' },
    { label: 'Setting Status', value: 'deploymentStatus' },
    { label: 'Description', value: 'description' },
    { label: 'Created', value: 'createdDateTime' },
    { label: 'Created By', value: 'createdBy' },
    { label: 'Last Applied', value: 'appliedAt' },
    { label: 'Last Applied By', value: 'appliedBy' },
    { label: 'Last Modified', value: 'updatedDateTime' },
    { label: 'Last Modified By', value: 'modifiedBy' },
    { label: 'Actions', value: 'actions' },
  ],
  androidUpdatesDeployment: [
    { label: 'Deployment Name', value: 'name' },
    { label: 'Status', value: 'finalStatus' },
    { label: 'Target Device Count', value: 'totalDevices' },
    { label: 'Results', value: 'results' },
    { label: 'Description', value: 'description' },
    { label: 'Completed On', value: 'completedOn' },
    { label: 'Actions', value: 'actions' },
  ],
  deviceUsers: [
    { label: 'Employee Id', value: 'employeeId' },
    { label: 'Security Type(s)', value: 'securityTypes' },
    { label: 'User Type', value: 'userType' },
    { label: 'Valid Through', value: 'validThrough' },
    { label: 'Created On', value: 'createdOn' },
    { label: 'Created By Device', value: 'createdByDevice' },
    { label: 'Created By S/N', value: 'createdByDeviceSerialNumber' },
    { label: 'Last Log-In', value: 'lastLogIn' },
    { label: 'Last Log-In Device', value: 'lastLogInDevice' },
    { label: 'Last Log-In S/N', value: 'lastLogInDeviceSerialNumber' },
    { label: 'Enrollment Id', value: 'enrollmentId' },
    { label: 'Actions', value: 'actions' },
  ],
  wirelessInsight: [
    { label: 'Total Device Count', value: 'deviceCount' },
    { label: 'Wi-Fi Device Count', value: 'wifiDeviceCount' },
    { label: 'Cellular Device Count', value: 'cellularDeviceCount' },
    { label: 'Wi-Fi Failures', value: 'wifiFailures' },
    { label: 'Cellular Failures', value: 'cellularFailures' },
    { label: 'Wi-Fi Quality', value: 'wifiQuality' },
    { label: 'Cellular Quality', value: 'cellularQuality' },
  ],
  wifiInsight: [
    { label: 'MODEL', value: 'deviceModel' },
    { label: 'WI-FI FAILURES', value: 'wifiFailures' },
    { label: 'WI-FI QUALITY', value: 'wifiQuality' },
    { label: 'SERIAL NUMBER', value: 'serial' },
    { label: 'OS VERSION', value: 'osVersion' },
    { label: 'SECURITY PATCH DATE', value: 'securityPatchDate' },
  ],
  wifiDeviceAllAnalysis: [
    { label: 'TIMESTAMP', value: 'utc_timestamp' },
    { label: 'EVENT TYPE (WLAN CODE)', value: 'wlan_code' },
    { label: 'REASON CODE', value: 'reason' },
    { label: 'FROM BSSID', value: 'from_bssid' },
    { label: 'TO BSSID', value: 'to_bssid' },
    { label: 'FROM RSSI', value: 'from_rssi' },
    { label: 'TO RSSI', value: 'to_rssi' },
    { label: 'FROM CHANNEL', value: 'from_channel' },
    { label: 'TO CHANNEL', value: 'to_channel' },
    { label: 'VOICE STATS', value: 'voiceStats' },
  ],
  wifiDeviceRoamAnalysis: [
    { label: 'REASON CODE', value: 'reason' },
    { label: 'FROM BSSID', value: 'from_bssid' },
    { label: 'TO BSSID', value: 'to_bssid' },
    { label: 'FROM RSSI', value: 'from_rssi' },
    { label: 'TO RSSI', value: 'to_rssi' },
    { label: 'FROM CHANNEL', value: 'from_channel' },
    { label: 'TO CHANNEL', value: 'to_channel' },
  ],
  cellularInsights: [
    { label: 'MODEL', value: 'deviceModel' },
    { label: 'CELLULAR FAILURES', value: 'cellularFailures' },
    { label: 'CELLULAR QUALITY', value: 'cellularQuality' },
    { label: 'SERIAL NUMBER', value: 'serial' },
    { label: 'OS VERSION', value: 'osVersion' },
    { label: 'SECURITY PATCH DATE', value: 'securityPatchDate' },
    { label: 'LOCATION', value: 'location' },
  ],
  cellularDeviceAllAnalysis: [
    { label: 'SUB ID', value: 'subscription_id' },
    { label: 'SPN', value: 'operator_name' },
    { label: 'RAT', value: 'rat' },
    { label: 'SIGNAL QUALITY', value: 'signal_strength' },
  ],
  cellularDeviceVoiceAnalysis: [
    { label: 'SUB ID', value: 'subscription_id' },
    { label: 'SPN', value: 'operator_name' },
    { label: 'RAT', value: 'rat' },
    { label: 'ERROR CODE', value: 'error_code' },
    { label: 'SIGNAL QUALITY', value: 'signal_strength' },
  ],
  cellularDeviceIPAnalysis: [
    { label: 'SUB ID', value: 'subscription_id' },
    { label: 'SPN', value: 'operator_name' },
    { label: 'RAT', value: 'rat' },
    { label: 'SIGNAL QUALITY', value: 'signal_strength' },
  ],
  cellularDeviceThroughputAnalysis: [
    { label: 'SUB ID', value: 'subscription_id' },
    { label: 'SPN', value: 'operator_name' },
    { label: 'RAT', value: 'rat' },
    { label: 'SIGNAL QUALITY', value: 'signal_strength' },
  ],
  logger: [
    { label: 'JOB NAME', value: 'name' },
    { label: 'STATUS', value: 'deploymentStatus' },
    { label: 'HOSTING LOCATION', value: 'hostingLocation' },
    { label: 'DATE COLLECTED', value: 'date' },
    // { label: 'DEVICE NAME', value: 'deviceNames' },
    // { label: 'DEVICE GROUP', value: 'groups' },
    { label: 'DESCRIPTION', value: 'description' },
  ],
  loggerDeviceSelection: [
    { label: 'Model', value: 'model' },
    { label: 'Device Name', value: 'deviceName' },
    { label: 'Device Group', value: 'groups' },
    { label: 'OS Version', value: 'formattedOsVersion' },
    { label: 'MX Version', value: 'mxVersion' },
    //{ label: 'Security Patch Date', value: 'securityPatch' },
    { label: 'Serial Number', value: 'serialNumber' },
    //{ label: 'IMEI', value: 'imei' },
    //{ label: 'Battery Health', value: 'batteryType' },
    //{ label: 'Last Connected', value: 'lastConnected' },
    //{ label: 'IP Address', value: 'ipAddress' },
    //{ label: 'Actions', value: 'actions' },
  ],
  loggerDetailDevices: [
    { label: 'Model', value: 'model' },
    { label: 'Device Name', value: 'deviceName' },
    { label: 'Device Group', value: 'groups' },
    //{ label: 'Security Patch Date', value: 'securityPatch' },
    { label: 'Serial Number', value: 'serialNumber' },
    { label: 'Status', value: 'deviceJobStatus' },
    //{ label: 'IMEI', value: 'imei' },
    //{ label: 'Battery Health', value: 'batteryType' },
    { label: 'Date Collected', value: 'updatedDateTime' },
    //{ label: 'IP Address', value: 'ipAddress' },
    //{ label: 'Actions', value: 'actions' },
  ],
}
export const HIDDEN_COLUMNS = {
  myDevices: [
    { label: 'Last Connected', value: 'lastConnected' },
    { label: 'IP Address', value: 'ipAddress' },
  ],
  newDeviceSetup: [],
  appSetup: [
    { label: 'Created', value: 'createdDateTime' },
    { label: 'Created By', value: 'createdBy' },
    { label: 'Last Applied', value: 'appliedAt' },
    { label: 'Last Applied By', value: 'appliedBy' },
    { label: 'Last Modified By', value: 'modifiedBy' },
  ],
  deviceView: [
    { label: 'Last Connected', value: 'lastConnected' },
  ],
  myCollection: [],
  deviceSettings: [
    { label: 'Created', value: 'createdDateTime' },
    { label: 'Created By', value: 'createdBy' },
    { label: 'Last Applied', value: 'appliedAt' },
    { label: 'Last Applied By', value: 'appliedBy' },
    { label: 'Last Modified By', value: 'modifiedBy' },
  ],
  androidUpdatesDeployment: [],
  deviceUsers: [
    { label: 'Valid Through', value: 'validThrough' },
    { label: 'Created On', value: 'createdOn' },
    { label: 'Created By Device', value: 'createdByDevice' },
    { label: 'Created By S/N', value: 'createdByDeviceSerialNumber' },
    { label: 'Last Log-In Device', value: 'lastLogInDevice' },
    { label: 'Enrollment Id', value: 'enrollmentId' },
  ],
  wirelessInsight: [],
  wifiInsight: [],
  wifiDeviceAllAnalysis: [],
  wifiDeviceRoamAnalysis: [],
  cellularInsights: [],
  cellularDeviceAllAnalysis: [],
  cellularDeviceVoiceAnalysis: [],
  cellularDeviceIPAnalysis: [],
  cellularDeviceThroughputAnalysis: [],
  logger: [],
  loggerDeviceSelection: [
    // { label: 'Last Connected', value: 'lastConnected' },
    // { label: 'IP Address', value: 'ipAddress' },
  ],
  loggerDetailDevices: [],
}
